<template>
  <div class="container-fluid">
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Kundennummer suchen..."
            v-model="query.customerId"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Name suchen..."
            v-model="query.name"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Postleitzahl suchen..."
            v-model="query.zipcode"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Ort suchen..."
            v-model="query.city"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <select
            v-model="selectedWeightType"
            class="form-control form-control-sm"
          >
            <option :value="null">Stückelung...</option>
            <option
              :value="weight.type"
              v-for="weight in weights"
              :key="weight._id"
            >
              {{ weight.type }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="number"
            min="0"
            class="form-control form-control-sm"
            placeholder="Gesamtgewicht suchen..."
            v-model.number="totalWeight"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <Multiselect
            v-model="selectedTeams"
            :options="multiSelectOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder="Teams auswählen"
            label="name"
            track-by="_id"
            :searchable="false"
            :show-labels="false"
          ></Multiselect>
          <!-- <select
            class="form-control form-control-sm"
            multiple
            v-model="selectedTeams"
          >
            <option disabled>Teams auswählen...</option>
            <option v-for="team in teams" :value="team" :key="team._id">
              {{ team.name }}
            </option>
          </select> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <FullCalendar
          :options="calendarOptions"
          ref="fullCalendar"
        ></FullCalendar>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Multiselect from 'vue-multiselect';
import { debounce } from 'lodash';
import { getBookings, getTeams, getWeights } from '../../api';
import { min, max, getTime, addDays, parseISO } from 'date-fns';

export default {
  name: 'calendar',
  components: {
    FullCalendar,
    Multiselect,
  },
  async created() {
    this.weights = await getWeights();
    this.teams = await getTeams();
    this.multiSelectOptions = [...this.teams];
  },
  data() {
    return {
      multiSelectOptions: [],
      calendarOptions: {
        plugins: [dayGridPlugin, bootstrapPlugin],
        initialView: 'dayGridDay',
        locale: 'de',
        firstDay: '1',
        weekNumbers: true,
        headerToolbar: {
          start: '',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay today prev,next',
        },
        buttonText: {
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
        },
        dayMaxEventRows: true,
        eventDisplay: 'block',
        views: {
          dayGridMonth: {
            dayMaxEventRows: 6,
          },
        },
        eventTextColor: 'white',
        displayEventTime: false,
        themeSystem: 'bootstrap',
        events: (info, successCb) => {
          const startTime = getTime(info.start.valueOf());
          const endTime = getTime(info.end.valueOf());
          const { name, city, zipcode, customerId } = this.query;

          getTeams().then((teams) => {
            getBookings({
              start: startTime,
              end: endTime,
              name,
              city,
              zipcode,
              customerId,
              withReservations: true,
            }).then((bookings) => {
              let events = bookings.map((booking) => {
                if (!booking.customer) {
                  booking.customer = {
                    name: booking.customerLegacy,
                    customerId: '',
                    city: '',
                    zipcode: '',
                  };
                }

                const weightsObj = booking.weights.reduce((prev, curr) => {
                  if (curr.amount) {
                    prev[curr.type] = curr;
                    prev[curr.type].value = curr.value * curr.amount;
                  }

                  return prev;
                }, {});

                const piecesStr = [];

                const team = teams.find((team) => {
                  return team.zipcodes.some((zipcode) =>
                    booking.customer.zipcode.startsWith(zipcode)
                  );
                });

                const teamName = team ? team.name : 'Nicht zugeordnet';

                Object.keys(weightsObj).forEach((key) => {
                  piecesStr.push(
                    ' | ' +
                      weightsObj[key].type +
                      ' - ' +
                      weightsObj[key].value +
                      'kg' +
                      ' | ' +
                      teamName
                  );
                });

                const weightsStr = piecesStr.reduce((prev, curr) => {
                  prev += curr;

                  return prev;
                }, '');

                const event = {
                  title: `${booking.customer.customerId} - ${
                    booking.customer.name
                  } - ${booking.customer.zipcode} - ${
                    booking.customer.city
                  } ${weightsStr} ${
                    booking.isReservation
                      ? '- RESERVIERUNG - ' + booking.ticketNr
                      : ''
                  }`,
                  zipcode: booking.customer.zipcode,
                  start: addDays(
                    min(booking.dates.map((date) => parseISO(date))),
                    1
                  ),
                  end: addDays(
                    max(booking.dates.map((date) => parseISO(date))),
                    1
                  ),
                  weights: booking.weights,
                  backgroundColor: booking.isReservation ? 'green' : null,
                };

                return event;
              });

              if (this.totalWeight) {
                events = events.filter((event) => {
                  const sum = event.weights.reduce((prev, curr) => {
                    if (curr.amount) {
                      prev += curr.value;
                    }

                    return prev;
                  }, 0);

                  return sum === this.totalWeight;
                });
              }

              if (this.selectedWeightType) {
                events = events.filter((event) => {
                  return event.weights
                    .filter((weight) => weight.amount > 0)
                    .map((weight) => weight.type)
                    .includes(this.selectedWeightType);
                });
              }

              if (this.selectedTeams.length) {
                events = events.filter((event) => {
                  return this.selectedTeams.some((selectedTeam) => {
                    return selectedTeam.zipcodes.some((zipcode) =>
                      event.zipcode.startsWith(zipcode)
                    );
                  });
                });
              }

              successCb(events);
            });
          });
        },
      },
      selectedWeightType: null,
      query: {
        name: '',
        zipcode: '',
        city: '',
        customerId: '',
      },
      totalWeight: null,
      selectedTeams: [],
      teams: [],
      weights: [],
    };
  },
  watch: {
    query: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
      deep: true,
    },
    totalWeight: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
    },
    selectedWeightType: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
    },
    selectedTeams: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
    },
  },
};
</script>

<style>
  /* div.multiselect__tags {
    padding: 0.25rem 0.5rem;
    min-height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.875rem;
    line-height: 1.5;
  } */
</style>
